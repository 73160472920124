var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module",on:{"keyup":_vm.trackActivity,"mousemove":_vm.trackActivity}},[_c('ACTASnackbar',{attrs:{"is-show":_vm.isShowCtaSnackbar,"snackbar-color":"#ea6764","ctas":_vm.ctaButtons},on:{"close":function($event){return _vm.$emit('closeSnackbar')}}}),(_vm.isShowTitleSnackbar)?_c('a-title-snackbar',{attrs:{"is-show":_vm.isShowTitleSnackbar,"snackbar-color":"grey","snackbar-message":"This is a public program","snackbar-timer":-1},on:{"close":function($event){return _vm.$emit('closeTitleSnackbar')}}}):_vm._e(),(_vm.isTemplate)?_c('div',{staticStyle:{"position":"relative","bottom":"15px","z-index":"99","pointer-events":"all"}},[_c('v-alert',{staticStyle:{"color":"black"},attrs:{"dismissible":"","density":"compact","type":"warning","title":"Alert title"}},[_vm._v("You are in template setup mode, the program functions won't work until you create a program from template.")])],1):_vm._e(),(
      _vm.userType === 'participant' &&
      !_vm.programDoc.data.isProgramCheckout &&
      _vm.programDoc.data.isProgramCheckoutBy
    )?_c('v-snackbar',{staticClass:"overlay unsponsored-snackbar",attrs:{"bottom":"","value":true,"timeout":"0","color":"accent","content-class":"text-center font-weight-bold white--text","light":"","width":"100vw","absolute":""}},[_vm._v(" This is an unsponsored program ")]):_vm._e(),(_vm.isPreviewAdk)?_c('div',{staticClass:"module__navbar",staticStyle:{"pointer-events":"all"}},[(_vm.currentPage != 'preview' && _vm.userType === 'organizer' && !_vm.isMonitor)?_c('v-btn',{staticClass:"module__navbar-button font-weight-bold",attrs:{"outlined":"","small":"","rounded":"","depressed":""},on:{"click":function($event){_vm.currentPage = 'preview'}}},[_vm._v("Preview")]):_vm._e(),(_vm.currentPage == 'preview' && _vm.userType === 'organizer' && _vm.operationMode === 1)?_c('v-btn',{staticClass:"module__navbar-button font-weight-bold",attrs:{"dark":"","small":"","rounded":"","depressed":"","color":"red"},on:{"click":function($event){_vm.currentPage = 'Setup'}}},[_vm._v("Exit Preview")]):_vm._e()],1):_vm._e(),(
      _vm.userType === 'participant' &&
      _vm.durationFormat &&
      _vm.programDoc &&
      _vm.programDoc.data &&
      _vm.programDoc.data.isShowTimer &&
      (!_vm.programDoc.data.timerType ||
        (_vm.programDoc.data.timerType &&
          (_vm.programDoc.data.timerType !== 'Count Down' ||
            (_vm.programDoc.data.timerType === 'Count Down' &&
              _vm.fieldsData &&
              _vm.fieldsData.idealActivityCompletionTime))))
    )?_c('div',{staticClass:"module__navbar"},[(_vm.programDoc.data.deadlineCompletion)?_c('v-chip',{staticClass:"ma-2 font-weight-bold overline",style:({ background: _vm.dynamicBgColor }),attrs:{"text-color":"white"}},[_c('div',{staticClass:"mr-2"},[_vm._v("Deadline:")]),_vm._v(" "+_vm._s(_vm.programDoc.data.deadlineCompletion)+" ")]):_c('v-chip',{staticClass:"ma-2 font-weight-bold overline",style:({ background: _vm.dynamicBgColor }),attrs:{"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.durationFormat)+" ")])],1):_vm._e(),_c('div',{staticClass:"module__container pb-0 mb-0",style:({
      'border-color': _vm.getColor,
      'pointer-events': !_vm.isTemplate && !_vm.isPreview && !_vm.isMonitor ? 'all' : 'none'
    })},[_c('div',{staticClass:"module__title"},[_c('div',{staticClass:"module__image rounded-circle"},[(_vm.fieldsDataValue)?_c('v-icon',{attrs:{"color":_vm.selectedColor,"light":"","x-large":""}},[_vm._v(_vm._s(_vm.fieldsDataValue.icon))]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column align-center text-center justify-center mt-12"},[_c('h1',{staticClass:"mt-auto mb-auto"},[_vm._v(_vm._s(_vm.moduleName))])])]),_c('div',{staticClass:"module__body"},[(
          _vm.userType === 'participant' &&
          _vm.fieldsDataValue &&
          _vm.fieldsDataValue.instruction &&
          Object.keys(_vm.fieldsDataValue.instruction).length !== 0
        )?_c('MInstruction',{attrs:{"instruction":_vm.fieldsDataValue.instruction}}):_vm._e(),(_vm.currentPage != 'preview')?_c('v-progress-linear',{attrs:{"color":"#dedede","height":"2","value":"100","buffer-value":"100","stream":""}}):_vm._e(),(_vm.currentPage !== 'preview' && _vm.currentPage !== 'default' && !_vm.isMonitor)?_c('div',{staticClass:"module__pagination"},_vm._l((_vm.subpages),function(page){return _c('div',{key:page,class:{ active: _vm.currentPage == page }},[_c('div',{staticClass:"module__pagination-button--active"}),_c('v-btn',{staticClass:"module__pagination-button",attrs:{"ripple":false,"elevation":"0","color":"#ffffff","height":"40","small":""},on:{"click":function($event){_vm.currentPage = page}}},[_vm._v(" "+_vm._s(page)+" ")])],1)}),0):_vm._e(),_vm._t("default"),(_vm.fieldsData)?_c('div',{staticClass:"module__page"},[_c('v-container',{class:_vm.containerClass,staticStyle:{"width":"98%"}},[_c('a-overlay-loader'),_c('ARenderer',{attrs:{"data":_vm.getComponent === 'module-presets'
                ? _vm.fieldsDataValue.presetFields
                : _vm.fieldsDataValue.fields,"program":_vm.programDoc,"is-preview":_vm.isPreview,"student-id":_vm.studentId,"is-monitor":_vm.isMonitor,"adk-type":_vm.adkTypeValue,"adk-color":_vm.fieldsData.styling && _vm.fieldsData.styling.borderColor,"setup-required":_vm.fieldsData.setupRequired,"adk-index":_vm.adkIndex,"user-type":_vm.userType,"fetch-program":_vm.fetchProgram,"user-doc":_vm.userDocValue,"is-visitor":_vm.isVisitor,"is-draft":_vm.isDraft,"is-template":_vm.isTemplate},on:{"input":function($event){return _vm.$emit('input', $event)},"next-page":function($event){return _vm.$emit('next-page')},"complete-activity":function($event){return _vm.$emit('complete-activity')},"open-auth-dialog":function($event){return _vm.$emit('open-auth-dialog')},"isSaveProgress":function($event){return _vm.$emit('isSaveProgress', 'save progress')},"emphasize-completion":function($event){return _vm.$emit('emphasize-completion')}}})],1)],1):_vm._e()],2)]),_c('v-dialog',{attrs:{"persistent":"","width":"700px"},model:{value:(_vm.inactivityDialog),callback:function ($$v) {_vm.inactivityDialog=$$v},expression:"inactivityDialog"}},[_c('v-card',[_c('v-container',[_c('div',{staticClass:"d-flex justify-center font-weight-bold mb-2 mt-4",staticStyle:{"font-size":"21px"}},[_vm._v(" Seems like you went inactive, click resume to continue. ")]),_c('div',{staticClass:"d-flex justify-center flex-row"},[_c('v-btn',{staticClass:"ma-2 mt-4",attrs:{"large":"","color":"green","rounded":"","depressed":"","dark":""},on:{"click":_vm.startTimer}},[_vm._v("Resume")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }